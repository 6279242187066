<style scoped>
.home {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

img.back {
  /* position: absolute;
  top: 17px;
  left: 20px;
  width: 16pt;
  height: 16pt; */
}

.title {
  /* font-size: 14pt; */
  font-size: 1rem;
  width: 100%;
  height: 7vh;
  line-height: 7vh;
  color: #333333;
  font-weight: bold;
  background: rgb(250, 250, 250);
  box-shadow: 0 2pt 2pt 0 rgba(230, 230, 230, 0.35);
}

.info {
  width: 100%;
  height: 12vh;
  margin-bottom: 1vh;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  /* padding: 1em; */
  box-sizing: border-box;
  text-align: left;
  align-items: center;
}

.info > div {
  display: inline-block;
  vertical-align: middle;
}

.info .headImg {
  width: 25%;
  height: 3.25rem;
  text-align: center;
}

.info .headImg img {
  /* width: 32pt;
  height: 32pt; */
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
}

.info .name {
  width: 35%;
  box-sizing: border-box;
  text-align: left;
}

.info .line {
  height: 2.28rem;
  width: 0.06rem;
  background: rgba(216, 216, 216, 0.39);
}

.info .number {
  width: calc(40% - 3px);
  text-align: center;
}

/* 
.rankAndRule {
  box-sizing: border-box;
  width: 100%;
  height: 9.41rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.rankAndRule > div {
  border-radius: 3pt;
  width: 29%;
  height: 100%;

  box-shadow: 0.08rem 0.17rem 1.56rem 0 rgba(0, 0, 0, 0.1);

  margin: 0 5px;
  padding: 20pt 0;
} */
.shadow {
  box-shadow: 0.08rem 0.17rem 1.56rem 0 rgba(0, 0, 0, 0.1);
}

/* 
.rankAndRule > div > img {
  width: 4.19rem;
  height: 3.81rem;
}
.rankAndRule > div > p {
  margin-top: 14%;
  font-size: 0.92em;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0.02rem;
  color: #333333;
} */
.answerHeader {
  width: 92%;
  margin: 20pt auto 0;
  text-align: left;
  position: relative;
}

.answerHeader p:first-child {
  font-size: 1.05rem;
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.answerHeader p:nth-child(2) {
  font-size: 0.85rem;
  color: rgb(153, 153, 153);
}

.answerHeader .lineLong {
  position: absolute;
  right: 0;
  top: 0.2rem;
  width: 1rem;
  height: 0.22rem;
  background: rgb(105, 103, 250);
}

.answerHeader .lineShort {
  position: absolute;
  right: 0;
  top: 0.55rem;
  width: 0.5rem;
  height: 0.22rem;
  background: rgb(105, 103, 250);
}

.answerBody {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92%;
  gap: 10px;
  margin: 0.8rem auto;
}

.answerBody > .pkAndDj {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.item {
  width: 100%;
  height: 6.9rem;
  background-size: 100% 100%;
  margin-bottom: 0.55rem;
  position: relative;
}

.answerBody .dayAnswer {
  background-image: url("../assets/dayAnswer.png");
}

.answerBody .weekAnswer {
  background-image: url("../assets/weekAnswer.png");
}

.answerBody .frienfPK {
  background-image: url("../assets/friendPK.png");
}

.answerBody .dangjian {
  background-image: url("../assets/dangjian.jpg");
}

.bottomNotice {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 24px;
  background: rgba(214, 214, 214, 0.9);
  color: #fff;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
}

.middle {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}

.tougaoback {
  position: absolute;
  padding: 0 20px;
  width: 100%;
  height: 3rem;
  background: none;
  color: #ffffff;
  line-height: 3rem;
  font-size: 1.2rem;
  box-shadow: none;
  display: flex;
  margin-bottom: 0;
  z-index: 10;
}

.new-info-grand {
  padding: 10px 15px;
  text-align: left;
}

.new-info-grand h2 {
  font-size: 1.05rem;
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.new-info-grand .info {
  /* margin:20px 0; */
  display: flex;
  padding: 0;
  justify-content: space-between;
}

.new-info-grand .info .grand {
  width: 48%;
  padding: 10px 15px;
  height: 80px;
  border-radius: 0.191rem;
  background: #e6edf7;
}

.new-info-grand .info .grand p {
  color: #aaa;
}

.new-info-grand .info .grand span {
  font-size: 20px;
  color: #3d7dcc;
}

.new-info .headImg {
  width: 20%;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  /* margin-right: 5%; */
}

.new-info .headImg img {
  width: 60px;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.new-info {
  display: flex;
  align-items: center;
  padding: 0px 20px 10px;
  text-align: left;
}

.new-info h2,
.answerHeader h2 {
  font-size: 1.2rem;
  color: rgb(51, 51, 51);
  font-weight: bold;
}

.new-info .info {
  width: 100%;

  display: flex;
  justify-content: space-between;
  background: none !important;
}

.new-info .info .grand {
  width: 34%;
  padding: 10px 10px;
  height: 80px;
  border-radius: 0.191rem;
  /* background: #e6edf7; */
  background: rgba(230, 237, 247, 0.5);
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-info .info .grand p {
  color: #aaa;
  text-align: center;
}

.new-info .info .grand span {
  font-size: 16px;
  color: #3d7dcc;
  font-weight: 700;
}
.new-info .info .grand:last-child span {
  color: #ec3115;
}
.new-infos {
  position: relative;
  width: 100%;

  height: 150px;
  color: #ffdd00;
  line-height: 150px;
  background-size: 100% 100%;
  background-image: url("../assets/lt-bgi.png");
  /* margin-bottom: 20px; */
}

.big {
  font-size: 2rem;
}

.smail {
  font-size: 1.5rem;
}
</style>
<template>
  <div class="home" style="">
    <div class="tougaoback">
      <div style="flex: 1; text-align: left">
        <span style="display: inline-block" @click="backList">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
      </div>
      <div style="flex: 1; text-align: center"></div>
      <div style="flex: 1; text-align: right"></div>
    </div>
    <div v-show="title.length >= 8" class="new-infos smail">
      {{ title }}
    </div>
    <div v-show="title.length <= 8" class="new-infos big">
      {{ title }}
    </div>

    <div class="new-info">
      <div class="info">
        <div class="grand">
          <div>历史</div>
          <div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 12px;
              "
            >
              <p>
                <span>{{ allCount }} </span> 次
              </p>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
              "
            >
              <p>
                <span>{{ allScore }}</span> 分
              </p>
            </div>
          </div>
        </div>
        <div class="headImg">
          <img :src="userInfo.avatar" />
        </div>
        <div class="grand">
          <div>今日</div>
          <div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 12px;
              "
            >
              <p>
                <span>{{ count }} </span> 次
              </p>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
              "
            >
              <p>
                <span>{{ score }}</span> 分
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="new-info-grand">
      <h2>个人中心</h2>
      <div class="info">
        <div class="grand">
          <p>
            <span>{{ count }}</span> 次
          </p>
          <div>参与次数</div>
        </div>
        <div class="grand">
          <p>
            <span>{{ score }}</span> 分
          </p>
          <div>总得分数</div>
        </div>
      </div>
    </div> -->
    <div class="info" style="display: none">
      <div class="number">
        <p
          style="font-size: 0.81rem; color: rgb(153, 153, 153); margin-top: 3px"
        >
          参与次数
          <span style="color: rgb(236, 189, 0); margin-left: 1rem"
            >&nbsp;&nbsp;&nbsp;{{ count }}</span
          >
        </p>
        <p
          style="font-size: 0.81rem; color: rgb(153, 153, 153); margin-top: 3px"
        >
          总得分数
          <span style="color: rgb(236, 189, 0); margin-left: 1rem"
            >&nbsp;&nbsp;&nbsp;{{ score }}</span
          >
        </p>
      </div>

      <div>
        <p
          @click="toRewardDetail"
          style="
            color: #f44336;
            font-size: 0.81rem;
            color: rgb(153, 153, 153);
            margin-top: 3px;
          "
        >
          奖励规则
        </p>
      </div>
    </div>
    <div>
      <h2
        style="
          font-size: 1.2rem;
          color: rgb(51, 51, 51);
          font-weight: bold;
          padding: 0px 20px 10px;
          text-align: left;
        "
      >
        答题榜单
      </h2>
      <div
        style="
          height: 9rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-sizing: border-box;
          gap: 4px;
          width: 92%;
          margin: 0 auto;
        "
      >
        <div
          style="
            height: 100%;

            padding: 10px 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32%;
          "
          class="shadow"
        >
          <div @click="goDetail('answer-detail')">
            <svg
              t="1701073365584"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5870"
              width="60"
              height="60"
            >
              <path
                d="M962 512c0 248.6-201.4 450-450 450-33 0-65.1-3.5-96.1-10.3C213.5 907.7 62 727.6 62 512 62 263.4 263.4 62 512 62c215.6 0 395.7 151.5 439.7 353.9 6.8 31 10.3 63.1 10.3 96.1z"
                fill="#3B89F1"
                p-id="5871"
              ></path>
              <path
                d="M962 512c0 248.6-201.4 450-450 450-33 0-65.1-3.5-96.1-10.3-109.4-68.8-182-190.6-182-329.2 0-214.6 174-388.6 388.6-388.6 138.7 0 260.4 72.7 329.2 182 6.8 31 10.3 63.1 10.3 96.1z"
                fill="#FFFFFF"
                p-id="5872"
              ></path>
              <path
                d="M332 622.5a290.5 290.5 0 1 0 581 0 290.5 290.5 0 1 0-581 0z"
                fill="#78B6F5"
                p-id="5873"
              ></path>
              <path
                d="M397.5 622.5a225 225 0 1 0 450 0 225 225 0 1 0-450 0z"
                fill="#FFFFFF"
                p-id="5874"
              ></path>
              <path
                d="M487.5 622.5a135 135 0 1 0 270 0 135 135 0 1 0-270 0z"
                fill="#5F9FF3"
                p-id="5875"
              ></path>
              <path
                d="M590.2 627.9L364.6 402.2c-8-8-8-20.9 0-28.9s20.9-8 28.9 0l225.6 225.6c8 8 8 20.9 0 28.9-7.9 8-20.9 8-28.9 0.1z"
                fill="#3B89F1"
                p-id="5876"
              ></path>
              <path
                d="M513.9 493.7L409.8 389.6l3.3-100.9 104.1 104.1z m-137.7-79.9l104.1 104.1-91.5 12.6-104.1-104.1z"
                fill="#59A6F5"
                p-id="5877"
              ></path>
            </svg>
            <!-- <img style="height: 5rem" src="../assets/ranking_all.png" alt="" /> -->
            <p>专项答题</p>
          </div>
        </div>

        <div
          style="
            height: 100%;

            padding: 10px 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32%;
          "
          class="shadow"
        >
          <div @click="goDetail('log-kicker')">
            <img
              style="width: 57%; height: 50%"
              src="../assets/ranking_day.png"
              alt=""
            />
            <p>我要踢榜</p>
          </div>
        </div>

        <div
          style="
            height: 100%;

            padding: 10px 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32%;
          "
          class="shadow"
        >
          <div @click="goDetail('ranking-list')">
            <img
              style="width: 57%; height: 50%"
              src="../assets/ranking_all.png"
              alt=""
            />
            <p>排行榜</p>
          </div>
        </div>
      </div>
    </div>
    <div class="answerHeader">
      <p>活动答题</p>
      <p>参与活动答题赢奖励</p>
      <div class="lineLong"></div>
      <div class="lineShort"></div>
    </div>
    <div class="answerBody">
      <div class="pkAndDj">
        <!--  <div
          v-show="activityId != '1716464441143a408fc44'"
          @click="
            $router.push({
              path: '/answer-detail-type',
              query: { number: 5, cyleType: '每日答题' },
            })
          "
          class="dayAnswer item"
        ></div> *-->
        <div @click="$router.push('/pkMain')" class="frienfPK item"></div>
      </div>
      <div class="pkAndDj">
        <!-- 
        <div
          v-show="activityId != '1716464441143a408fc44'"
          class="weekAnswer item"
          @click="
            $router.push({
              path: '/answer-detail-type',
              query: { number: 40, cyleType: '每周答题' },
            })
          "
        ></div>
        *-->
        <div
          @click="goDetail('answer-detail-exercise')"
          class="dangjian item"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { wxConPost } from "../api/httpApi"; // Data request API
import globalData from "../api/globalData";
import _ from "lodash";
export default {
  name: "activityMain",
  data () {
    return {
      activityId: "",
      userInfo: {},
      userDept: "",
      activity: {},
      score: 0,
      count: 0,

      allScore: 0,
      allCount: 0,
    };
  },

  components: {},
  methods: {
    backList () {
      globalData.setActivity({});
      this.$router.push("/answer-list");
    },
    goDetail (page) {
      this.$router.replace(`/${page}`);
    },
    working () {
      // alert("功能正在开发！！");
    },
    toRewardDetail () {
      if (!this.activity.rewardId) {
        this.$Message["info"]({
          background: true,
          content: "活动未设置奖励规则",
        });
        return;
      } else {
        this.$router.push({
          path: "/detailCon",
          query: {
            id: this.activity.rewardId,
          },
        });
      }
    },
    async getUserData () {
      const result = await wxConPost(
        "/services/gttt-answer-exam/dept/getUserData",
        {}
      );
      if (result.dept_2nd_name) {
        this.userDept = result.dept_2nd_name;
      }
      // 获取历史总成绩，总次数信息
      const gradeResult = await wxConPost(
        "/services/gttt-answer-exam/rank/getUserInfo",
        { id: this.activity.id, type: "allStats" }
      );
      if (gradeResult.ret) {
        this.allScore = gradeResult.ret.score ? gradeResult.ret.score : 0;
        this.allCount = gradeResult.ret.count ? gradeResult.ret.count : 0;
      }

      // 获取当日总成绩，总次数信息
      const gradeDayResult = await wxConPost(
        "/services/gttt-answer-exam/rank/getUserInfo",
        { id: this.activity.id, type: "day" }
      );
      if (gradeDayResult.ret) {
        this.score = gradeDayResult.ret.score ? gradeDayResult.ret.score : 0;
        this.count = gradeDayResult.ret.count ? gradeDayResult.ret.count : 0;
      }
    },
  },
  created () {
    this.title = localStorage.getItem("gtlt");
    this.activity = globalData.getActivity();
    this.activityId = localStorage.getItem("activityId");
    if (!_.isEmpty(this.activity)) {
      this.userInfo = JSON.parse(localStorage.user);
      this.getUserData();
    } else {
      this.backList();
    }
  },
};
</script>
<style></style>
